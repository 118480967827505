export const API_URL = process.env.REACT_APP_API_URL;
export const API_V = "v1";
/*
 * Some url is same. update and create made by post query, fetch data and reload with get.
 */

export const API_ROUTES = {
  login: "auth/login",
  logout: "logout",
  auth_business: "auth/business",
  auth_user_phone: "auth/user_phone",
  me: "me",
  user_change_password: "change_password",
  recommendations: "auth/check/recommendations",

  cases: "cases",
  case: "cases/:_id",
  cases_count: "cases_count",
  case_reports: "cases/:_id/reports",
  case_report_next_step: "reports/next_step/:_id",
  case_report: "cases/:caseId/reports/:_id",
  case_report_export: "cases/:caseId/reports/:_id/export",
  case_finances: "cases/:_id/transactions",
  case_finance: "cases/:caseId/transactions/:_id",
  case_finance_delete: "cases/:caseId/transactions/:_id",
  case_finance_export: "cases/:caseId/transactions_export",
  case_finances_status_update: "cases/finance_update_statuses",
  case_todos: "cases/:_id/todos",
  case_todo: "cases/:caseId/todos/:_id",
  case_todo_delete: "cases/:_id/todos/:_id",

  case_todo_notes: "cases/:caseId/todos/:todoId/notes",
  case_todo_note: "cases/:caseId/todos/:todoId/notes/:_id",

  case_feedback_statistics: "cases/feedbacks/statistics",
  case_feedbacks: "cases/:_id/feedbacks",
  case_feedback: "cases/:caseId/feedbacks/:feedbackId",
  case_notes: "cases/:_id/notes",
  case_note: "cases/:caseId/notes/:_id",
  case_timeTracks: "cases/:_id/time_tracks",
  case_timeTrack: "cases/:caseId/time_tracks/:_id",

  case_plans: "cases/:_id/plans",
  case_plan: "cases/:caseId/plans/:_id",
  case_plan_delete: "cases/:caseId/plans/:_id",
  case_plan_notes: "cases/:caseId/plans/:plan/notes",
  case_plan_note: "cases/:caseId/plans/:plan/notes/:_id",

  businesses: "businesses",
  business: "businesses/:_id",

  calls: "calls",
  callstats: "calls/stats",

  documents: "documents",
  document_delete: "documents/:document",
  document: "documents/:_id",

  case_document_folders: "cases/:caseId/document_folders",
  case_document_folder: "cases/:caseId/document_folders/:_id",

  switchUser: "auth/switchUser",
  siblings: "users/:_id/siblings",
  sibling: "users/:_id/siblings/:sibling",
  users: "users",
  users_minlist: "users_minlist",
  users_count: "users_count",
  user: "users/:_id",
  user_reason: "users/:_id/reason",
  user_upload: "users/:userId/upload",
  user_restore: "restore_user/:_id",
  user_contract_restore: "users/:userId/restorecontract/:_id",
  user_delete: "users/:_id",
  user_cases: "users/:userId/cases",
  user_case: "users/:userId/cases/:_id",
  user_contracts: "users/:userId/contracts",
  user_contract: "users/:userId/contracts/:_id",
  user_contract_notes: "users/:userId/contracts/:_id/notes",
  user_contract_note: "users/:userId/contracts/:contract/notes/:_id",
  user_contract_sign: "users/:userId/contracts/:_id/sign",
  user_contracts_archive: "users/:userId/archivecontracts",
  user_contracts_count: "users/:userId/contractscount",
  user_notifications: "users/:userId/notifications",
  user_notes: "users/:userId/notes",
  user_note: "users/:userId/notes/:_id",
  user_document_folders: "users/:userId/document_folders",
  user_document_folder: "users/:userId/document_folders/:_id",
  user_todos: "users/:userId/todos",

  user_timeTrack_sum_year: "users/:id/time_tracks_sum_year",
  user_timeTrack_sum_week: "users/:id/time_tracks_sum_week",
  user_timeTrack_sum_month: "users/:id/time_tracks_sum_month",

  user_timeTracks: "users/:_id/time_tracks",
  user_timeTrack: "users/:user/time_tracks/:_id",
  user_feedbacks: "users/:_id/feedbacks",

  user_penalties: "users/:userId/penalties",
  user_penalty: "users/:userId/penalties/:_id",

  user_penalty_docs: "users/:userId/penalty_documents",

  user_finances: "users/:_id/transactions",
  user_finance: "users/:userId/transactions/:_id",
  user_finance_delete: "users/:userId/transactions/:_id",
  user_finance_export: "users/:userId/transactions_export",
  user_finances_status_update: "users/finance_update_statuses",

  roles: "roles",
  role: "roles/:_id",

  permissions: "permissions",
  permission_generate: "permissions/generate",
  permission: "permissions/:_id",

  settings: "settings",
  setting: "settings/:_id",
  setting_delete: "settings/:_id",

  folders: "folders",
  folder: "folders/:_id",
  folder_delete: "folders/:_id",

  folder_notes: "folders/:folderId/notes",
  folder_note: "folders/:folderId/notes/:_id",
  folder_notes_count: "folder_notes_count",
  folders_count: "folders_count",

  vacancies: "vacancies",
  vacancy: "vacancies/:_id",
  vacancy_delete: "vacancies/:vacancyId",
  vacancy_notes: "vacancies/:vacancyId/notes",
  vacancy_note: "vacancies/:vacancyId/notes/:_id",

  knowledgebase_categories: "knowledgebase_categories",
  knowledgebase_category: "knowledgebase_categories/:_id",
  knowledgebase_category_delete: "knowledgebase_categories/:_id",

  knowledgebase_category_items:
    "knowledgebase_categories/:knowledgebaseCategoryId/items",
  knowledgebase_category_item:
    "knowledgebase_categories/:knowledgebaseCategoryId/items/:_id",

  todos: "todos",
  todos_minlist: "todos/minlist",
  todo: "todos/:_id",
  todo_delete: "todos/:_id",
  todo_notes: "todos/:_id/notes",
  todo_note: "todos/:todo/notes/:_id",

  todos_count: "todos_count",

  robots: "robots",
  robot: "robots/:_id",

  time_tracks: "time_tracks",
  time_track_on: "time_track/on",
  time_track_off: "time_track/:_id/off",
  time_track_pause: "time_track/:_id/pause",
  time_track_play: "time_track/:_id/play",
  time_track: "time_tracks/:_id",
  time_track_delete: "time_tracks/:_id",
  timezones: "time_tracks/timezones",

  case_timeTrack_surveys: "cases/:caseId/time_track_surveys",

  case_timeTrack_sum_year: "cases/:caseId/time_tracks_sum_year",
  case_timeTrack_sum_week: "cases/:caseId/time_tracks_sum_week",
  case_timeTrack_sum_month: "cases/:caseId/time_tracks_sum_month",

  mailboxes: "mailboxes",
  mailbox: "mailboxes/:_id",
  mailbox_delete: "mailboxes/:_id",

  offers: "offers",
  offers_status_update: "offers/update_statuses",
  offers_count: "offers_count",
  offer: "offers/:offerId",
  offer_delete: "offers/:offerId",

  offer_notes: "offers/:offerId/notes",
  offer_note: "offers/:offerId/notes/:_id",

  incoming_offers: "incoming_offers",
  incoming_offer: "incoming_offers/:_id",
  incoming_offer_delete: "incoming_offers/:_id",

  logs: "logs",
  statistics: "statistics",
  contracts_total: "control_panel/contracts",
  todos_total: "control_panel/todos",
  events_total: "control_panel/events",
  cases_total: "control_panel/cases",
  offers_total: "control_panel/offers",
  active_time_track_users: "control_panel/active_time_track_users",

  surveys: "surveys",

  guidelines: "guidelines",
  guideline: "guidelines/:_id",

  values: "values",
  value: "values/:_id",

  reports: "reports",
  report: "reports/:_id",
  report_delete: "reports/:_id",

  projects_minlist: "projects_minlist",
  projects: "projects",
  project: "projects/:_id",

  tasks: "tasks",
  task: "tasks/:_id",
  task_leave: "tasks/:_id/leave",
  task_move: "tasks/:_id/move",
  task_complete: "tasks/:_id/complete",
  task_start: "tasks/:_id/start",
  tasks_count: "tasks_count",

  task_comments: "tasks/:task/task_comments",
  task_comment: "tasks/:task/task_comments/:_id",

  columns: "columns",
  column: "columns/:_id",
  column_list: "projects/data",
  card_move: "cards/move",
  column_move: "columns/move",

  invoices: "invoices",
  invoice: "invoices/:_id",
  invoices_count: "invoices/count",
  invoice_send: "invoices/:_id/send",
  invoice_pdf: "invoices/pdf",

  expenses: "accounting/expenses",
  expense: "accounting/expenses/:_id",
  expenses_count: "accounting/expenses/count",

  templates: "accounting/templates",
  template: "accounting/templates/:_id",

  suppliers: "accounting/suppliers",
  supplier: "accounting/suppliers/:_id",

  tax_rates: "accounting/tax_rates",
  tax_rate: "accounting/tax_rates/:_id",

  credit_note: "invoices/:_id/credit_note",

  contractTemplates: "contract_templates",
  contractTemplate: "contract_templates/:_id",
  contractTemplate_delete: "contract_templates/:_id",

  contracts: "contract_templates/:templateId/contracts",
  contract: "contract_templates/:templateId/contracts/:_id",

  _contracts: "contracts",
  _contract: "contracts/:_id",
  _contract_export: "contracts/:_id/export",

  chats: "chats",
  chat: "chats/:_id",
  chat_messages: "chats/:_id/messages",
  chat_message_delete: "chats/:_id/messages/:_message",
  chat_remove_user: "chats/:_id/remove-user",
  chat_archive: "chats/:_id/archive",
  chat_read: "chats/:_id/read",

  communes: "communes",
  communes_count: "communes_count",
  commune: "communes/:_id",
  commune_users: "communes/:_id/users",

  profile: "users/:_id",

  files: "files",
  file: "files/:_id",

  notifications: "notifications",

  activities: "activities",
  activity: "activities/{activity}",
  activity_count: "activities/count",
  reset_activity_count: "activities/resetcount",

  translations: "translations",
  translation: "translations/:_id",
  translate_with_ai: "translations/translate/ai",

  search: "search",
  message: "message",
  modules: "modules",
  export: "export/csv",
};

export const getRoute = (api_route) => {
  return `${API_URL}/${API_V}/${api_route}`;
};
