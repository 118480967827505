import React from "react";
import {
  IUrlQuery,
  IUser,
  IRestApiCollection,
  IRestApiError,
  IRestApiResource,
  IBudget,
} from "../interfaces";
import { http } from "../lib/http";
import { API_ROUTES, getRoute } from "../config/apiRoutes";
import { generateFormData, queryToString } from "../utils/http";
import { ISetting } from "./setting.service";
import { ICommune } from "./comunne.service";
import { ITodo, ITodoStore } from "./todo.service";
import { ITimeTrack } from "./timeTrack.service";
import { IDocument } from "./document.service";
import { INote, INoteStore } from "./note.service";
import { IReport, IReportStore } from "./report.service";

export interface ICase {
  _id: string;
  users: Array<IUser>;
  users_titles: Array<{ _id: string; title: string }>;
  focus_area: ISetting | null;
  focus_type: ISetting | null;
  employee_position: ISetting | null;
  employee_rate: string;
  commune: ICommune | null;
  notice_commune: ISetting | null;
  report: boolean;
  default: true;
  report_type: ISetting | null;
  report_interval: ISetting | null;
  meeting_duration: string;
  meeting_durations: {
    [key in string]: {
      month: string;
      administration_work: number;
      face_to_face: number;
    }[];
  };
  meeting_duration_type: ISetting | null;
  administration_work: number;
  face_to_face: number;

  budget: IBudget;
  init_budget: number;
  budget_interval: ISetting | null;
  budget_limit: number;

  last_report_date: string;
  next_report_date: string;
  ask_transport_calculation: string;
  cancellation_note: string;
  balance: string;
  spent_amount: string;
  spent_timer: string;

  start_date_report: string;
  start_date_case: string;
  start_date_employee: string;
  start_date_finance: string;
  expire_date_commune: string;
  last_timer_date: string;

  time_record_remain: number;
  key: string;

  _created_at: string;
  _updated_at: string;
}

interface ICaseStoreDataSignature {
  [key: string]: any;
}

export interface ICaseStoreData extends ICaseStoreDataSignature {
  focus_area: string;
  focus_type: string;
  users: Array<string>;
  users_titles: Array<{ _id: string; title: string }>;
  report: boolean;
  report_type: string;
  report_interval: string;

  meeting_duration: string;
  meeting_duration_type: string;
  budget: string;
  budget_interval: string;
  budget_limit: string;
  start_date_report: string;
  start_date_case: string;
  start_date_employee: string;
  start_date_finance: string;
}

export interface ICaseNote {
  _id: string;
  title: string;
  description: string;

  _created_at: string;
  _updated_at: string;
}

export interface ICaseDocumentFolder {
  _id: string;
  title: string;
  category: ISetting;
  documents: Array<IDocument>;

  _created_at: string;
  _updated_at: string;
}

export interface ICaseDocumentFolderStore {
  _id: string;
  files: Array<string>;
  title: string;
  category: string;
}

export interface ICasePlan {
  _id: string;
  goal: ISetting;
  type: ISetting;
  status: ISetting;
  locked: number;
  plan_questions: Array<{
    _id: string;
    interval: string;
  }>;
  action_plan: string;
  responsible_person: IUser;
}

export interface ICaseFeedback {
  _id: string;
  user: IUser;
  emoji: number;
  comment: string;
}

export interface ICaseQuickCardsCount {
  data: {
    archived: number;
    expired: number;
    not_started: number;
  };
}

export interface ICaseTimetrackFeedback {
  time_track: string;
  user: string;
  emoji: number;
  comment: string;
}

export interface ICaseTimetrackSurvey {
  _id: string;
  case: string;
  answers: Array<ICaseTimetrackSurvey>;
}

// export interface ICaseGoal {
//     title: { [key: string]: string },
//     active: number,
//     business: string,
//     focus_area: Array<ISetting>,
// }

export interface ICaseTimeTrackQuestions {
  title: { [key: string]: string };
  _id: string;
  active: number;
  business: string;
  categories: Array<string>;
  created_by: string;
  default_lang: string;
  goal: ISetting;
  index: number;
  plan: ICasePlan;
  slug: string;
  survey: ICaseTimetrackSurvey;
  time_track: ITimeTrack;
  users: Array<string>;
  answered_text: string;
}

export interface ICaseTimeTrackAnswer {
  answered_text: string;
  question: string;
  plan: string;
  survey: string;
}

export interface ICaseTimeTrackAnswers {
  data: Array<ICaseTimeTrackAnswer>;
}

const INDEX = API_ROUTES.cases;
const SHOW = API_ROUTES.case;
const STORE = API_ROUTES.cases;
const DELETE = API_ROUTES.case;

const QUICK_CARDS_COUNT = API_ROUTES.cases_count;

const PLAN_INDEX = API_ROUTES.case_plans;
const PLAN_SHOW = API_ROUTES.case_plan;
const PLAN_DELETE = API_ROUTES.case_plan_delete;

const PLAN_NOTES_INDEX = API_ROUTES.case_plan_notes;
const PLAN_NOTE_SHOW = API_ROUTES.case_plan_note;

const DOCUMENT_INDEX = API_ROUTES.case_document_folders;
const DOCUMENT_SHOW = API_ROUTES.case_document_folder;
const DOCUMENT_DELETE = API_ROUTES.case_document_folder;

const REPORT_INDEX = API_ROUTES.case_reports;
const REPORT_STORE = API_ROUTES.case_reports;
const REPORT_SHOW = API_ROUTES.case_report;
const REPORT_NEXT_STEP = API_ROUTES.case_report_next_step;
const REPORT_EXPORT = API_ROUTES.case_report_export;

const FINANCE_INDEX = API_ROUTES.case_finances;
const FINANCE_STORE = API_ROUTES.case_finances;
const FINANCE_SHOW = API_ROUTES.case_finance;
const FINANCE_UPDATE = API_ROUTES.case_finance;
const FINANCE_DELETE = API_ROUTES.case_finance;
const FINANCE_EXPORT = API_ROUTES.case_finance_export;
const FINANCES_UPDATE_STATUS = API_ROUTES.case_finances_status_update;

const TODOS_INDEX = API_ROUTES.case_todos;
const TODO_SHOW = API_ROUTES.case_todo;

const TODO_NOTES_INDEX = API_ROUTES.case_todo_notes;
const TODO_NOTE_SHOW = API_ROUTES.case_todo_note;

const FEEDBACK_STATISTICS = API_ROUTES.case_feedback_statistics;
const FEEDBACK_INDEX = API_ROUTES.case_feedbacks;
const FEEDBACK_SHOW = API_ROUTES.case_feedback;

const NOTE_INDEX = API_ROUTES.case_notes;
const NOTE_SHOW = API_ROUTES.case_note;
const NOTE_UPDATE = API_ROUTES.case_note;
const NOTE_DELETE = API_ROUTES.case_note;

const TIMETRACK_INDEX = API_ROUTES.case_timeTracks;
const TIMETRACK_DELETE = API_ROUTES.case_timeTrack;
const TIMETRACK_UPDATE = API_ROUTES.case_timeTrack;
const TIMETRACK_SURVEYS = API_ROUTES.case_timeTrack_surveys;
const TIMETRACK_SUM_YEAR = API_ROUTES.case_timeTrack_sum_year;
const TIMETRACK_SUM_WEEK = API_ROUTES.case_timeTrack_sum_week;
const TIMETRACK_SUM_MONTH = API_ROUTES.case_timeTrack_sum_month;

export interface ICaseService {
  // case
  getAll(
    query?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<ICase> | IRestApiError>;

  getQuickCardsCount(
    query?: Array<IUrlQuery>
  ): Promise<ICaseQuickCardsCount | IRestApiError>;

  add(data: ICaseStoreData): Promise<IRestApiResource<ICase> | IRestApiError>;

  getOne(_id: string): Promise<IRestApiResource<ICase> | IRestApiError>;

  update(
    _id: string,
    data: ICaseStoreData
  ): Promise<IRestApiResource<ICase> | IRestApiError>;

  delete(
    _id: string,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<ICase> | IRestApiError>;

  // archive
  getAllArchive(
    query?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<ICase> | IRestApiError>;

  // plan
  getPlans(
    _id: string,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<any> | IRestApiError>;

  postPlan(
    _id: string,
    data: any,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiResource<any> | IRestApiError>;

  getOnePlan(
    caseId: string,
    _id: string,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiResource<ICasePlan> | IRestApiError>;

  updatePlan(
    caseId: string,
    _id: string,
    data: any,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiResource<ICasePlan> | IRestApiError>;

  deletePlan(
    caseId: string,
    _id: string
  ): Promise<IRestApiCollection<any> | IRestApiError>;

  getPlanNotes(
    caseId: string,
    plan: string,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<INote> | IRestApiError>;

  addPlanNotes(
    caseId: string,
    plan: string,
    data: any
  ): Promise<IRestApiCollection<INote> | IRestApiError>;

  updatePlanNote(
    caseId: string,
    plan: string,
    _id: string,
    data: any,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiResource<INote> | IRestApiError>;

  deletePlanNote(
    caseId: string,
    plan: string,
    _id: string
  ): Promise<IRestApiCollection<INote> | IRestApiError>;

  // document folder
  getDocFolders(
    _id: string,
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<IDocument> | IRestApiError>;

  getOneDocFolder(
    caseId: string,
    _id: string,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiResource<ICaseDocumentFolder> | IRestApiError>;

  updateDocFolder(
    caseId: string,
    _id: string,
    data: ICaseDocumentFolderStore,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiResource<ICaseDocumentFolder> | IRestApiError>;

  postDocFolder(
    _id: string,
    data: ICaseDocumentFolderStore,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiResource<ICaseDocumentFolder> | IRestApiError>;

  deleteDocFolder(
    caseId: string,
    _id: string
  ): Promise<IRestApiCollection<IDocument> | IRestApiError>;

  // report
  getReports(
    _id: string,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<any> | IRestApiError>;

  getOneReport(
    caseId: string,
    _id: string,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiResource<any> | IRestApiError>;

  updateReport(
    caseId: string,
    _id: string,
    data: IReportStore,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiResource<any> | IRestApiError>;

  postReport(
    _id: string,
    data: IReportStore,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiResource<IReport> | IRestApiError>;

  deleteReport(
    caseId: string,
    _id: string,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<IReport> | IRestApiError>;

  reportNextStep(
    _id: string,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiResource<any> | IRestApiError>;

  exportReport(
    caseId: string,
    _id: string,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiResource<any> | IRestApiError>;

  // finance
  getFinances(
    _id: string,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<any> | IRestApiError>;

  postFinance(
    _id: string,
    data: any,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiResource<any> | IRestApiError>;

  updateFinancesStatus(
    data: { ids: string[]; status: string; case: string },
    query?: Array<IUrlQuery>
  ): Promise<{ status: string } | IRestApiError>;

  getOneFinance(
    caseId: string,
    _id: string,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiResource<any> | IRestApiError>;

  updateFinance(
    caseId: string,
    _id: string,
    data: any,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiResource<any> | IRestApiError>;

  deleteFinance(
    caseId: string,
    _id: string
  ): Promise<IRestApiCollection<any> | IRestApiError>;

  exportFinance(
    caseId: string,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiResource<any> | IRestApiError>;

  // todo
  getTodos(
    _id: string,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<ITodo> | IRestApiError>;

  deleteTodo(
    caseId: string,
    _id: string,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<ITodo> | IRestApiError>;

  postTodo(
    _id: string,
    data: ITodoStore,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiResource<ITodo> | IRestApiError>;

  getOneTodo(
    caseId: string,
    _id: string,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiResource<ITodo> | IRestApiError>;

  updateTodo(
    caseId: string,
    _id: string,
    data: any,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiResource<ITodo> | IRestApiError>;

  // feedback
  getFeedbackStatistics(query?: Array<IUrlQuery>): Promise<any>;

  getFeedbacks(
    _id: string,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<ICaseFeedback> | IRestApiError>;

  getOneFeedback(
    caseId: string,
    _id: string,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiResource<ICaseFeedback> | IRestApiError>;

  // note
  getNotes(
    _id: string,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<any> | IRestApiError>;

  postNote(
    _id: string,
    data: ICaseNote,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiResource<any> | IRestApiError>;

  getOneNote(
    caseId: string,
    _id: string,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiResource<INote> | IRestApiError>;

  updateNote(
    caseId: string,
    _id: string,
    data: any,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiResource<ICaseNote> | IRestApiError>;

  deleteNote(
    caseId: string,
    _id: string
  ): Promise<IRestApiCollection<ICase> | IRestApiError>;

  // time track
  getTimeTracks(
    _id: string,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<ITimeTrack> | IRestApiError>;

  postTimeTrack(
    _id: string,
    data: any,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiResource<any> | IRestApiError>;

  getActiveTrack(_id: string): Promise<IRestApiResource<any> | IRestApiError>;

  getTimeTrackSurveys(
    caseId: string,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiResource<any> | IRestApiError>;

  getTimeTracksSumWeek(
    caseId: string,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiResource<{ spent: number }> | IRestApiError>;

  getTimeTracksSumYear(
    caseId: string,
    query?: Array<IUrlQuery>
  ): Promise<
    IRestApiResource<{ spent: number; total: number }[]> | IRestApiError
  >;
  getTimeTracksSumMonth(
    caseId: string,
    query?: Array<IUrlQuery>
  ): Promise<
    | IRestApiResource<
        {
          [key: string]: {
            hour: number;
            color: string;
          };
        }[]
      >
    | IRestApiError
  >;

  updateTimeTrackSurveys(
    caseId: string,
    data: ICaseTimeTrackAnswers,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiResource<ICaseTimeTrackAnswers> | IRestApiError>;

  updateTimeTrack(
    caseId: string,
    _id: string,
    data: any,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiResource<ITimeTrack> | IRestApiError>;

  deleteTimeTrack(
    caseId: string,
    _id: string
  ): Promise<IRestApiCollection<any> | IRestApiError>;

  postTimeTrackFeedback(
    caseId: string,
    data: ICaseTimetrackFeedback,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiResource<any> | IRestApiError>;

  // todo note
  getTodoNotes(
    caseId: string,
    todoId: string,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<any> | IRestApiError>;

  postTodoNote(
    caseId: string,
    todoId: string,
    data: INoteStore,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiResource<INote> | IRestApiError>;

  getTodoNote(
    caseId: string,
    todoId: string,
    _id: string,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiResource<INote> | IRestApiError>;

  updateTodoNote(
    caseId: string,
    todoId: string,
    _id: string,
    data: INoteStore,
    query?: Array<IUrlQuery>
  ): Promise<IRestApiResource<INote> | IRestApiError>;

  deleteTodoNote(
    caseId: string,
    todoId: string,
    _id: string
  ): Promise<IRestApiCollection<INote> | IRestApiError>;
}

const caseService: ICaseService = {
  // case
  async getAll(query?: Array<IUrlQuery>) {
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(`${getRoute(INDEX)}${queryString}`, "get");
  },

  async getQuickCardsCount(query?: Array<IUrlQuery>) {
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(`${getRoute(QUICK_CARDS_COUNT)}${queryString}`, "get");
  },

  async add(data: ICaseStoreData) {
    let formData = generateFormData(new FormData(), data);
    return http(getRoute(STORE), "post", formData);
  },

  async getOne(_id) {
    return http(`${getRoute(SHOW).replace(":_id", _id.toString())}`, "get");
  },

  async update(_id, data: ICaseStoreData) {
    let jsonData = JSON.stringify(data);
    return http(
      `${getRoute(SHOW).replace(":_id", _id.toString())}`,
      "put",
      jsonData
    );
  },

  async delete(_id, query) {
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(
      getRoute(`${DELETE.replace(":_id", _id.toString())}${queryString}`),
      "delete"
    );
  },

  // archive
  async getAllArchive(query?: Array<IUrlQuery>) {
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(`${getRoute(INDEX)}${queryString}`, "get");
  },

  // plan
  async getPlans(_id, query: Array<IUrlQuery>) {
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(
      `${getRoute(PLAN_INDEX).replace(":_id", _id.toString())}${queryString}`,
      "get"
    );
  },

  async postPlan(_id, data: any, query?: Array<IUrlQuery>) {
    let formData = generateFormData(new FormData(), data);
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(
      `${getRoute(PLAN_INDEX).replace(":_id", _id.toString())}${queryString}`,
      "post",
      formData
    );
  },

  async getOnePlan(caseId, _id, data, query?: Array<IUrlQuery>) {
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(
      `${getRoute(PLAN_SHOW)
        .replace(":caseId", caseId.toString())
        .replace(":_id", _id.toString())}${queryString}`,
      "get"
    );
  },

  async updatePlan(caseId, _id, data, query?: Array<IUrlQuery>) {
    let queryString = query ? `?${queryToString(query)}` : "";
    let jsonData = JSON.stringify(data);
    return http(
      `${getRoute(PLAN_SHOW)
        .replace(":caseId", caseId.toString())
        .replace(":_id", _id.toString())}${queryString}`,
      "put",
      jsonData
    );
  },

  async deletePlan(caseId, _id) {
    return http(
      getRoute(
        PLAN_DELETE.replace(":caseId", caseId.toString()).replace(
          ":_id",
          _id.toString()
        )
      ),
      "delete"
    );
  },

  async getPlanNotes(caseId, plan, query?: Array<IUrlQuery>) {
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(
      `${getRoute(PLAN_NOTES_INDEX)
        .replace(":caseId", caseId.toString())
        .replace(":plan", plan.toString())}${queryString}`,
      "get"
    );
  },

  async addPlanNotes(caseId, plan, data) {
    let formData = generateFormData(new FormData(), data);

    return http(
      `${getRoute(PLAN_NOTES_INDEX)
        .replace(":caseId", caseId.toString())
        .replace(":plan", plan.toString())}`,
      "post",
      formData
    );
  },

  async updatePlanNote(caseId, plan, _id, data, query?: Array<IUrlQuery>) {
    let queryString = query ? `?${queryToString(query)}` : "";
    let jsonData = JSON.stringify(data);
    return http(
      `${getRoute(PLAN_NOTE_SHOW)
        .replace(":caseId", caseId.toString())
        .replace(":plan", plan.toString())
        .replace(":_id", _id.toString())}${queryString}`,
      "put",
      jsonData
    );
  },

  async deletePlanNote(caseId, plan, _id, query?: Array<IUrlQuery>) {
    return http(
      getRoute(
        PLAN_NOTE_SHOW.replace(":caseId", caseId.toString())
          .replace(":plan", plan.toString())
          .replace(":_id", _id.toString())
      ),
      "delete"
    );
  },
  // document folders
  async getDocFolders(_id, queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";
    return http(
      `${getRoute(DOCUMENT_INDEX).replace(
        ":caseId",
        _id.toString()
      )}${queriesString}`,
      "get"
    );
  },

  async postDocFolder(
    _id,
    data: ICaseDocumentFolderStore,
    query?: Array<IUrlQuery>
  ) {
    let formData = generateFormData(new FormData(), data);

    // console.log("formData", data);
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(
      `${getRoute(DOCUMENT_INDEX).replace(
        ":caseId",
        _id.toString()
      )}${queryString}`,
      "post",
      formData
    );
  },

  async getOneDocFolder(caseId, _id, query?: Array<IUrlQuery>) {
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(
      `${getRoute(DOCUMENT_SHOW)
        .replace(":caseId", caseId.toString())
        .replace(":_id", _id.toString())}${queryString}`,
      "get"
    );
  },

  async updateDocFolder(
    caseId,
    _id,
    data: ICaseDocumentFolderStore,
    query?: Array<IUrlQuery>
  ) {
    /**
     * TODO _method PUT is Laravel put content bug. check google for it
     */
    let queryString = query
      ? `?${queryToString([
          ...query,
          {
            name: "_method",
            value: "PUT",
          },
        ])}`
      : "?_method=PUT";

    let formData = generateFormData(new FormData(), data);
    return http(
      `${getRoute(DOCUMENT_SHOW)
        .replace(":caseId", caseId.toString())
        .replace(":_id", _id.toString())}${queryString}`,
      "post",
      formData
    );
  },

  async deleteDocFolder(caseId, _id) {
    return http(
      `${getRoute(DOCUMENT_DELETE)
        .replace(":caseId", caseId.toString())
        .replace(":_id", _id.toString())}`,
      "delete"
    );
  },

  // report
  async getReports(_id, query: Array<IUrlQuery>) {
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(
      `${getRoute(REPORT_INDEX).replace(":_id", _id.toString())}${queryString}`,
      "get"
    );
  },

  async getOneReport(caseId, _id, query?: Array<IUrlQuery>) {
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(
      `${getRoute(REPORT_SHOW)
        .replace(":caseId", caseId.toString())
        .replace(":_id", _id.toString())}${queryString}`,
      "get"
    );
  },

  async updateReport(
    caseId,
    _id,
    data: IReportStore,
    query?: Array<IUrlQuery>
  ) {
    let queryString = query ? `?${queryToString(query)}` : "";
    let jsonData = JSON.stringify(data);
    return http(
      `${getRoute(REPORT_SHOW)
        .replace(":caseId", caseId.toString())
        .replace(":_id", _id.toString())}${queryString}`,
      "put",
      jsonData
    );
  },

  async reportNextStep(_id, query?: Array<IUrlQuery>) {
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(
      `${getRoute(REPORT_NEXT_STEP).replace(
        ":_id",
        _id.toString()
      )}${queryString}`,
      "post"
    );
  },

  async deleteReport(caseId, _id, query: Array<IUrlQuery>) {
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(
      `${getRoute(REPORT_SHOW)
        .replace(":caseId", caseId.toString())
        .replace(":_id", _id.toString())}${queryString}`,
      "delete"
    );
  },

  async postReport(_id, data: IReportStore, query?: Array<IUrlQuery>) {
    let formData = generateFormData(new FormData(), data);
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(
      `${getRoute(REPORT_STORE).replace(":_id", _id.toString())}${queryString}`,
      "post",
      formData
    );
  },

  async exportReport(caseId, _id, query: Array<IUrlQuery>) {
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(
      `${getRoute(REPORT_EXPORT)
        .replace(":caseId", caseId.toString())
        .replace(":_id", _id.toString())}${queryString}`,
      "get"
    );
  },

  // finance
  async getFinances(_id, query: Array<IUrlQuery>) {
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(
      `${getRoute(FINANCE_INDEX).replace(
        ":_id",
        _id.toString()
      )}${queryString}`,
      "get"
    );
  },

  async postFinance(_id, data: any, query?: Array<IUrlQuery>) {
    let formData = generateFormData(new FormData(), data);
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(
      `${getRoute(FINANCE_STORE).replace(
        ":_id",
        _id.toString()
      )}${queryString}`,
      "post",
      formData
    );
  },

  async updateFinancesStatus(
    data: { ids: string[]; status: string; case: string },
    query?: Array<IUrlQuery>
  ) {
    let formData = generateFormData(new FormData(), data);
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(
      `${getRoute(FINANCES_UPDATE_STATUS)}${queryString}`,
      "post",
      formData
    );
  },

  async getOneFinance(caseId, _id, query?: Array<IUrlQuery>) {
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(
      `${getRoute(FINANCE_SHOW)
        .replace(":caseId", caseId.toString())
        .replace(":_id", _id.toString())}${queryString}`,
      "get"
    );
  },

  async updateFinance(caseId, _id, data: any, query?: Array<IUrlQuery>) {
    /**
     * TODO _method PUT is Laravel put content bug. check google for it
     */
    let queryString = query
      ? `?${queryToString([
          ...query,
          {
            name: "_method",
            value: "PUT",
          },
        ])}`
      : "?_method=PUT";

    let formData = generateFormData(new FormData(), data);
    return http(
      `${getRoute(FINANCE_UPDATE)
        .replace(":caseId", caseId.toString())
        .replace(":_id", _id.toString())}${queryString}`,
      "post",
      formData
    );
  },
  async deleteFinance(caseId, _id) {
    return http(
      getRoute(
        FINANCE_DELETE.replace(":caseId", caseId.toString()).replace(
          ":_id",
          _id.toString()
        )
      ),
      "delete"
    );
  },

  async exportFinance(caseId, query: Array<IUrlQuery>) {
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(
      `${getRoute(FINANCE_EXPORT).replace(
        ":caseId",
        caseId.toString()
      )}${queryString}`,
      "get"
    );
  },

  // todo
  async getTodos(_id, query: Array<IUrlQuery>) {
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(
      `${getRoute(TODOS_INDEX).replace(":_id", _id.toString())}${queryString}`,
      "get"
    );
  },

  async deleteTodo(caseId, _id, query: Array<IUrlQuery>) {
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(
      `${getRoute(TODO_SHOW)
        .replace(":caseId", caseId.toString())
        .replace(":_id", _id.toString())}${queryString}`,
      "delete"
    );
  },

  async postTodo(_id, data: any, query?: Array<IUrlQuery>) {
    let formData = generateFormData(new FormData(), data);
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(
      `${getRoute(TODOS_INDEX).replace(":_id", _id.toString())}${queryString}`,
      "post",
      formData
    );
  },

  async getOneTodo(caseId, _id, query?: Array<IUrlQuery>) {
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(
      `${getRoute(TODO_SHOW)
        .replace(":caseId", caseId.toString())
        .replace(":_id", _id.toString())}${queryString}`,
      "get"
    );
  },

  async updateTodo(caseId, _id, data: any, query?: Array<IUrlQuery>) {
    let queryString = query ? `?${queryToString(query)}` : "";
    let jsonData = JSON.stringify(data);
    return http(
      `${getRoute(TODO_SHOW)
        .replace(":caseId", caseId.toString())
        .replace(":_id", _id.toString())}${queryString}`,
      "put",
      jsonData
    );
  },

  // feedback
  async getFeedbackStatistics(query?: Array<IUrlQuery>) {
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(`${getRoute(FEEDBACK_STATISTICS)}${queryString}`, "get");
  },

  async getFeedbacks(_id, query: Array<IUrlQuery>) {
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(
      `${getRoute(FEEDBACK_INDEX).replace(
        ":_id",
        _id.toString()
      )}${queryString}`,
      "get"
    );
  },

  async getOneFeedback(caseId, _id, query: Array<IUrlQuery>) {
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(
      `${getRoute(FEEDBACK_SHOW)
        .replace(":caseId", caseId.toString())
        .replace(":feedbackId", _id.toString())}${queryString}`,
      "get"
    );
  },

  // note
  async getNotes(_id, query: Array<IUrlQuery>) {
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(
      `${getRoute(NOTE_INDEX).replace(":_id", _id.toString())}${queryString}`,
      "get"
    );
  },

  async postNote(_id, data, query?: Array<IUrlQuery>) {
    let formData = generateFormData(new FormData(), data);
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(
      `${getRoute(NOTE_INDEX).replace(":_id", _id.toString())}${queryString}`,
      "post",
      formData
    );
  },

  async getOneNote(caseId, _id, query?: Array<IUrlQuery>) {
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(
      `${getRoute(NOTE_SHOW)
        .replace(":caseId", caseId.toString())
        .replace(":_id", _id.toString())}${queryString}`,
      "get"
    );
  },

  async updateNote(caseId, _id, data, query?: Array<IUrlQuery>) {
    /**
     * TODO _method PUT is Laravel put content bug. check google for it
     */
    let queryString = query
      ? `?${queryToString([
          ...query,
          {
            name: "_method",
            value: "PUT",
          },
        ])}`
      : "?_method=PUT";

    let formData = generateFormData(new FormData(), data);
    return http(
      `${getRoute(NOTE_UPDATE)
        .replace(":caseId", caseId.toString())
        .replace(":_id", _id.toString())}${queryString}`,
      "post",
      formData
    );
  },

  async deleteNote(caseId, _id) {
    return http(
      `${getRoute(NOTE_DELETE)
        .replace(":caseId", caseId.toString())
        .replace(":_id", _id.toString())}`,
      "delete"
    );
  },

  // time track
  async getTimeTracks(_id, query: Array<IUrlQuery>) {
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(
      `${getRoute(TIMETRACK_INDEX) //TODO feedback api isnt ready yet.
        .replace(":_id", _id.toString())}${queryString}`,
      "get"
    );
  },

  async getTimeTrackSurveys(caseId: string, query: Array<IUrlQuery>) {
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(
      `${getRoute(TIMETRACK_SURVEYS).replace(
        ":caseId",
        caseId.toString()
      )}${queryString}`,
      "get"
    );
  },

  async getTimeTracksSumWeek(caseId: string, query: Array<IUrlQuery>) {
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(
      `${getRoute(TIMETRACK_SUM_WEEK).replace(
        ":caseId",
        caseId.toString()
      )}${queryString}`,
      "get"
    );
  },

  async getTimeTracksSumMonth(caseId: string, query: Array<IUrlQuery>) {
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(
      `${getRoute(TIMETRACK_SUM_MONTH).replace(
        ":caseId",
        caseId.toString()
      )}${queryString}`,
      "get"
    );
  },

  async getTimeTracksSumYear(caseId: string, query: Array<IUrlQuery>) {
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(
      `${getRoute(TIMETRACK_SUM_YEAR).replace(
        ":caseId",
        caseId.toString()
      )}${queryString}`,
      "get"
    );
  },

  async updateTimeTrackSurveys(
    caseId: string,
    data: ICaseTimeTrackAnswers,
    query?: Array<IUrlQuery>
  ) {
    let queryString = query ? `?${queryToString(query)}` : "";
    let jsonData = JSON.stringify(data);
    return http(
      `${getRoute(TIMETRACK_SURVEYS).replace(
        ":caseId",
        caseId.toString()
      )}${queryString}`,
      "put",
      jsonData
    );
  },

  async getActiveTrack(_id: string) {
    return http(`${getRoute(SHOW).replace(":_id", _id.toString())}`, "get");
  },

  async postTimeTrack(_id, data, query?: Array<IUrlQuery>) {
    let formData = generateFormData(new FormData(), data);
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(
      `${getRoute(TIMETRACK_INDEX).replace(
        ":_id",
        _id.toString()
      )}${queryString}`,
      "post",
      formData
    );
  },

  async updateTimeTrack(caseId, _id, data, query?: Array<IUrlQuery>) {
    let queryString = query ? `?${queryToString(query)}` : "";
    let jsonData = JSON.stringify(data);
    return http(
      `${getRoute(TIMETRACK_UPDATE)
        .replace(":caseId", caseId.toString())
        .replace(":_id", _id.toString())}${queryString}`,
      "put",
      jsonData
    );
  },

  async deleteTimeTrack(caseId, _id) {
    return http(
      getRoute(
        TIMETRACK_DELETE.replace(":caseId", caseId.toString()).replace(
          ":_id",
          _id.toString()
        )
      ),
      "delete"
    );
  },

  async postTimeTrackFeedback(
    caseId: string,
    data: ICaseTimetrackFeedback,
    query?: Array<IUrlQuery>
  ) {
    let formData = generateFormData(new FormData(), data);
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(
      `${getRoute(FEEDBACK_INDEX).replace(
        ":_id",
        caseId.toString()
      )}${queryString}`,
      "post",
      formData
    );
  },

  // todo note
  async getTodoNotes(caseId, todoId, query?: Array<IUrlQuery>) {
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(
      `${getRoute(TODO_NOTES_INDEX)
        .replace(":caseId", caseId.toString())
        .replace(":todoId", todoId.toString())}${queryString}`,
      "get"
    );
  },

  async postTodoNote(caseId, todoId, data, query?: Array<IUrlQuery>) {
    let formData = generateFormData(new FormData(), data);
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(
      `${getRoute(TODO_NOTES_INDEX)
        .replace(":caseId", caseId.toString())
        .replace(":todoId", todoId.toString())}${queryString}`,
      "post",
      formData
    );
  },

  async getTodoNote(caseId, todoId, _id, query?: Array<IUrlQuery>) {
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(
      `${getRoute(TODO_NOTE_SHOW)
        .replace(":caseId", caseId.toString())
        .replace(":todoId", todoId.toString())
        .replace(":_id", _id.toString())}${queryString}`,
      "get"
    );
  },

  async updateTodoNote(
    caseId,
    todoId,
    _id,
    data: INoteStore,
    query?: Array<IUrlQuery>
  ) {
    /**
     * TODO _method PUT is Laravel put content bug. check google for it
     */
    let queryString = query
      ? `?${queryToString([
          ...query,
          {
            name: "_method",
            value: "PUT",
          },
        ])}`
      : "?_method=PUT";

    let formData = generateFormData(new FormData(), data);
    return http(
      `${getRoute(TODO_NOTE_SHOW)
        .replace(":caseId", caseId.toString())
        .replace(":todoId", todoId.toString())
        .replace(":_id", _id.toString())}${queryString}`,
      "post",
      formData
    );
  },

  async deleteTodoNote(caseId, todoId, _id) {
    return http(
      `${getRoute(TODO_NOTE_SHOW)
        .replace(":caseId", caseId.toString())
        .replace(":todoId", todoId.toString())
        .replace(":_id", _id.toString())}`,
      "delete"
    );
  },
};

export { caseService };
