import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { IColumns } from "app/partials/layout/DataTable";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { GridTable } from "app/partials/layout/GridTable";
import { useTable } from "app/hooks/subresource/useTable";
import { caseService, ICase } from "app/services/case.service";
import { FormattedMessage, useIntl } from "react-intl";
import { PermissionsGate } from "app/permission/PermissionsGate";
import ConfirmDialog from "app/partials/dialogues/confirmDialogue";
import { useParams } from "react-router";
import FinanceStore from "../store/financeStore";
import moment from "moment";
import ShowFinance from "./showFinance";
import { makeStyles } from "@material-ui/core";
import FinanceFilter from "./filters/financeFilter";
import { IFinance } from "../../../interfaces";
import Snackbar from "../../../widgets/Snackbar";
import { IFile } from "../../../services/file.service";
import { toAbsoluteUrl } from "../../../../_metronic";
import EditIcon from "../../../partials/icons/EditIcon";
import Controls from "../../../widgets/uncontrolled";
import { useSelector } from "react-redux";
import { RootState } from "app/store/store";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  calendar: {
    zIndex: 99999,
  },
  dateRangeInput: {
    width: "100%",
    borderRadius: 8,
    border: "none!important",
    "& .ant-picker-focused": {
      boxShadow: "none!important",
    },
    "& .ant-picker": {
      border: "1px solid #E6E6E6!important",
      height: 40,
      backgroundColor: "#fff",
      borderRadius: 8,
      width: "100%",
      color: "#000000!important",
      "& .ant-picker-input input::placeholder": {
        color: "rgba(0, 0, 0, 0.6)",
      },
    },
  },
  input: {
    "& .MuiFormControl-root": {
      "& .MuiOutlinedInput-root": {
        "& .MuiOutlinedInput-input": {
          padding: "11px!important",
        },
      },
      "& .MuiInputLabel-root": {
        top: "-5px",
      },
      height: 40,
      backgroundColor: "#fff",
      border: "1px solid #E6E6E6",
      borderRadius: 8,
      m: () => ({}),
      "& .MuiOutlinedInput-notchedOutline": {
        border: "unset",
      },
    },
  },
  arrow: {
    "&:before": {
      border: "1px solid #E6E8ED",
    },
    color: theme.palette.common.white,
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    border: "1px solid #E6E8ED",
    color: "#4A4A4A",
    width: 500,
  },
}));

const Finance: FC<ICase> = (props) => {
  const { id } = useParams<{ id: string }>();
  let { search } = useLocation();
  let query = useMemo(() => new URLSearchParams(search), [search]);

  const _id = query.get("id");
  const intl = useIntl();
  const [total, setTotal] = useState<number>(0);
  const classes = useStyles();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [actionConfirm, setActionConfirm] = useState<boolean>(false);
  const [status, setStatus] = useState<string | null>(null);

  const { data, xhrLoading, remove, refresh, updateQuery, setQuery, queries } =
    useTable<IFinance>({
      fetch: caseService.getFinances,
      delete: caseService.deleteFinance,
      param: id,
      query: [
        {
          name: "per_page",
          value: 5,
        },
      ],
    });

  useEffect(() => {
    if (data) {
      let params = data?.data;
      let totalAmount = 0;

      params?.length > 0
        ? params.map((param: IFinance) => {
            if (param.direction === "outcome") totalAmount += +param.amount;
            setTotal(totalAmount);
          })
        : setTotal(0);
    }
  }, [data]);

  useEffect(() => {
    if (data && _id) {
      setFinanceId(_id);
    }
  }, [data, _id]);

  const [confirmId, setConfirmId] = useState<string | null>(null);
  const tableRef = useRef();
  const [init, setInit] = useState<boolean>(false);

  const { finance_statuses } = useSelector(
    (state: RootState) => state.settingReducer
  );

  // @ts-ignore
  const columns: IColumns[] = [
    {
      field: "_id",
      headerName: "ID",
      valueGetter: (params) => {
        return params.row?._id.toString();
      },
      hide: true,
    },
    {
      field: "title",
      headerName: intl.formatMessage({ id: "STANDARD.TITLE" }),
      minWidth: 360,
    },
    {
      field: "_created_at",
      headerName: intl.formatMessage({ id: "STANDARD.DATE" }),
      valueGetter: (params) => {
        return moment.unix(params.row?.date).local().format("DD/MM/YYYY");
      },
    },
    {
      field: "amount",
      headerName: intl.formatMessage({ id: "CASE.FINANCE.AMOUNT" }),
      renderCell: (params) => {
        return (
          <Typography
            color={params.row?.direction === "income" ? "green" : "red"}
            variant={"body1"}
          >
            {(params.row?.direction === "income" ? "+" : "-") +
              params.row?.amount +
              " " +
              props.budget.currency}
          </Typography>
        );
      },
    },
    {
      field: "Status",
      headerName: intl.formatMessage({ id: "STANDARD.STATUS" }),
      renderCell: (params) => {
        return (
          <div className="d-flex flex-row align-items-center">
            <span style={{ color: params.row?.status?.color }}>
              {params.row?.status?.title &&
                params.row?.status?.title[intl.locale]}
            </span>
          </div>
        );
      },
      minWidth: 200,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.ACTIONS" }),
      field: "actions",
      renderCell: (params) => {
        return (
          <>
            <Tooltip
              placement={"left-end"}
              classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
              arrow
              title={
                params.row?.documents?.length > 0 && (
                  <Box sx={{ width: "100%" }}>
                    <Box sx={{ mt: 1, mx: 2 }}>
                      <Grid container spacing={1}>
                        {params.row?.documents?.map((document: IFile) => {
                          return (
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                              <Avatar
                                alt={document.name}
                                sx={{
                                  backgroundColor: "transparent",
                                  "& .MuiAvatar-img": {
                                    objectFit: "scale-down",
                                  },
                                  width: "100%",
                                  height: "100%",
                                }}
                                src={getFileExtensionIcon(document)}
                                variant={"square"}
                              />
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Box>
                  </Box>
                )
              }
            >
              <IconButton>
                <i
                  style={{ display: "flex", color: "#FBB73C" }}
                  className="fi fi-sr-eye"
                />
              </IconButton>
            </Tooltip>
            <PermissionsGate section={"activity_payments"} scope={"read"}>
              <IconButton
                onClick={() => show(params.row?._id)}
                data-cy-class={"case_finance_show"}
                data-cy-case-id={id}
                data-cy-finance-id={params.row?._id}
              >
                <EditIcon />
              </IconButton>
            </PermissionsGate>
            <PermissionsGate section={"activity_payments"} scope={"delete"}>
              <IconButton
                onClick={() => {
                  handleClick(params.row?._id);
                }}
                data-cy-class={"case_finance_delete"}
                data-cy-case-id={id}
                data-cy-finance-id={params.row?._id}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.5 5.33333V3.66667C16.5 3.22464 16.3244 2.80072 16.0118 2.48816C15.6993 2.17559 15.2754 2 14.8333 2H9.83333C9.39131 2 8.96738 2.17559 8.65482 2.48816C8.34226 2.80072 8.16667 3.22464 8.16667 3.66667V5.33333H4V7H5.66667V19.5C5.66667 20.163 5.93006 20.7989 6.3989 21.2678C6.86774 21.7366 7.50363 22 8.16667 22H16.5C17.163 22 17.7989 21.7366 18.2678 21.2678C18.7366 20.7989 19 20.163 19 19.5V7H20.6667V5.33333H16.5ZM11.5 16.1667H9.83333V11.1667H11.5V16.1667ZM14.8333 16.1667H13.1667V11.1667H14.8333V16.1667ZM14.8333 5.33333H9.83333V3.66667H14.8333V5.33333Z"
                    fill="#EC3033"
                  />
                </svg>
              </IconButton>
            </PermissionsGate>
          </>
        );
      },
      type: "actions",
      align: "right",
      flex: 1,
      headerAlign: "right",
      minWidth: 160,
      sortable: false,
    },
  ];

  const getFileExtensionIcon = (file: any): string => {
    let name = file.name;

    let _name = name.split(".")?.pop() ?? "";
    let ret: string;

    switch (_name) {
      case "xls":
      case "xlsx":
        ret = toAbsoluteUrl("/media/extension/xlsx.svg");
        break;
      case "pdf":
        ret = toAbsoluteUrl("/media/extension/pdf.svg");
        break;
      default:
        ret = file.base64;
        break;
    }

    return ret;
  };

  useEffect(() => {
    if (!xhrLoading) setInit(true);
  }, [xhrLoading]);

  const handleClick = (_id: string) => {
    setConfirmId(_id);
  };

  const [financeId, setFinanceId] = useState<string | null>(null);

  const show = (_id: string) => {
    setFinanceId(_id);
  };

  const handleStatus = () => {
    if (status) {
      if (!selectedIds.length) Snackbar.error("Choose least 1 row");

      console.log("props.key", props);
      caseService
        .updateFinancesStatus({ ids: selectedIds, status, case: id })
        .then((data) => {
          if ("status" in data) {
            Snackbar.success(intl.formatMessage({ id: "SNACKBAR.UPDATED" }));
            refresh();
          }
        });
    }
  };

  const handleExport = () => {
    caseService.exportFinance(id).then((data) => {
      if ("error" in data) {
        throw Error(intl.formatMessage({ id: "SNACKBAR.SOMETHINGWENTWRONG" }));
      }

      const url = "data:application/zip;base64," + data.data;
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `act_${moment(new Date()).format("DD-MM-YYYY")}_${moment(
          new Date()
        ).format("HH:mm")}.zip`
      );
      document.body.appendChild(link);
      link.click();
      link?.parentNode?.removeChild(link);

      Snackbar.info(intl.formatMessage({ id: "SNACKBAR.DOWNLOADED" }));
    });
  };

  return (
    <Box>
      <PermissionsGate section={"activity_payments"} scope={"delete"}>
        {confirmId ? (
          <ConfirmDialog
            title="Delete payment?"
            open={Boolean(confirmId)}
            setOpen={() => setConfirmId(null)}
            onConfirm={() => {
              remove(id, confirmId);
            }}
          >
            Are you sure you want to delete this case payment?
          </ConfirmDialog>
        ) : (
          <></>
        )}
      </PermissionsGate>
      <PermissionsGate section={"activity_payments"} scope={"read"}>
        {financeId ? (
          <ShowFinance
            _case={props._id}
            _id={financeId}
            open={Boolean(financeId)}
            setDialogue={() => setFinanceId(null)}
            onDone={refresh}
          />
        ) : (
          <></>
        )}
      </PermissionsGate>
      <PermissionsGate section={"activity_payments"} scope={"read"}>
        <GridTable
          loading={xhrLoading}
          skeleton={!init}
          pageSize={data?.per_page}
          count={data?.total}
          customTotal={total}
          customTotalCurrency={props.budget.currency}
          columns={columns}
          rows={data?.data ?? []}
          tableRef={tableRef}
          filterModel={undefined}
          query={(queries) => updateQuery(queries)}
          actionIds={(ids) => {
            setSelectedIds(ids);
            setActionConfirm(true);
          }}
          actionToolbar={{
            status: () => {
              return (
                <Box
                  sx={{
                    marginRight: 2,
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  <Box sx={{ width: 124 }}>
                    <Controls.Select
                      labelSx={{ top: "-5px" }}
                      sx={{
                        width: 124,
                        height: 40,
                        backgroundColor: "#fff",
                        border: "1px solid #E6E6E6",
                        borderRadius: 2,
                        m: () => ({}),
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "unset",
                        },
                      }}
                      name={"status"}
                      options={finance_statuses?.map((f_i) => {
                        return {
                          id: f_i._id.toString(),
                          title: f_i.title[intl.locale],
                        };
                      })}
                      label={intl.formatMessage({ id: "STANDARD.STATUS" })}
                      onChange={(event) => {
                        setStatus(event.target.value);
                      }}
                    />
                  </Box>
                  <Box sx={{ width: 124 }}>
                    <Button
                      autoFocus
                      color="inherit"
                      type={"button"}
                      sx={{
                        width: 124,
                        backgroundColor: "#0D99FF",
                        borderRadius: 2,
                        color: "#ffffff",
                        height: 40,
                      }}
                      onClick={() => {
                        handleStatus();
                      }}
                    >
                      <FormattedMessage
                        id={"STANDARD.EXECUTE"}
                        defaultMessage={"Execute"}
                      />
                    </Button>
                  </Box>
                </Box>
              );
            },
          }}
          filterToolbar={{
            actionPanel: (props: { open: boolean; close: () => void }) => {
              return (
                <FinanceStore
                  _id={id}
                  open={props.open}
                  setDialogue={props.close}
                  onDone={refresh}
                />
              );
            },
            filters: (props: { show: boolean }) => {
              return (
                <FinanceFilter
                  show={props.show}
                  queries={queries}
                  setQuery={setQuery}
                  updateQuery={updateQuery}
                />
              );
            },
            exportPanel: () => handleExport(),
          }}
          search={(queries) => updateQuery(queries)}
          quickSearchToolbar={true}
        />
      </PermissionsGate>
    </Box>
  );
};

export default Finance;
