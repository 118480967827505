import React, { CSSProperties, FC, useEffect, useRef, useState } from "react";
import Skeleton from "@mui/material/Skeleton";
import { makeStyles } from "@material-ui/core";
import {
  DataGrid,
  GridCallbackDetails,
  GridColDef,
  GridFilterModel,
  GridOverlay,
  GridSortModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  MuiEvent,
} from "@mui/x-data-grid";
import {
  Box,
  LinearProgress,
  Paper,
  TextField,
  Typography,
  IconButton,
  Grid,
  TablePagination,
  Pagination,
  PaginationItem,
} from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { IUrlQuery } from "../../interfaces";
import { GridCellParams } from "@mui/x-data-grid/models/params/gridCellParams";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";
import { GridCellEditCommitParams } from "@mui/x-data-grid/models/params/gridEditCellParams";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Snackbar from "../../widgets/Snackbar";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  table: {
    width: "100%",
    borderRadius: 8,
  },
  iconButtonFilter: {
    "&:hover": {
      backgroundColor: "#E1E6FB!important",
    },
  },
  iconButtonAdd: {
    "&:hover": {
      backgroundColor: "#556EE6!important",
    },
  },
  iconButtonExport: {
    "&:hover": {
      backgroundColor: "#4BB543!important",
    },
  },
  iconButtonPenaltyDoc: {
    "&:hover": {
      backgroundColor: "#0D99FF!important",
    },
  },
  input: {
    "&::placeholder": {
      color: "#2A3042",
    },
  },
  border: {
    outline: "1px solid #556EE6",
  },
}));

type GridProps = {
  loading: boolean;
  skeleton: boolean;
  sx?: SxProps<Theme>;
  title?: string;
  subTitle?: string;
  count?: number;
  customTotal?: number | string;
  customTotalCurrency?: string;
  pageSize?: number;
  columns: GridColDef[];
  rows: Array<any>;
  style?: CSSProperties;
  actions?: Array<any>;
  tableRef?: any;
  query: (value: Array<IUrlQuery>) => void;
  actionPanel?: JSX.Element;
  filterModel?: GridFilterModel;
  searchText?: string;
  search?: (value: Array<IUrlQuery>) => void;
  extraToolbar?: JSX.Element;
  quickSearchToolbar?: boolean;
  columnsToolbar?: boolean;
  filterToolbar?: {
    quickCards?: Array<{
      title: string;
      onClick: (isActive: boolean) => void;
      icon?: string;
      value?: number;
    }>;
    filters?: (props: { show: boolean }) => JSX.Element;
    customQuickCards?: JSX.Element;
    action?: () => void; // if we want to forward you to new page
    actionPanel?: (props: { open: boolean; close: () => void }) => JSX.Element;
    exportPanel?: () => void; // if we want to export to any format file
    penaltyDocuments?: () => void; // if we want to export to any format file
  };
  actionToolbar?: {
    status?: () => JSX.Element;
  };
  archiveAction?: (ids: Array<string>) => void;
  archiveOption?: boolean;
  actionIds?: (ids: Array<string>) => void;
  actionTool?: (props: { show: boolean }) => JSX.Element;
  densityToolbar?: boolean;
  exportToolbar?: boolean;
  onCellEditStart?: (params: GridCellParams, event: MuiEvent) => void;
  onCellEditStop?: (params: GridCellParams, event: MuiEvent) => void;
  onCellEditCommit?: (
    params: GridCellEditCommitParams,
    event: MuiEvent
  ) => void;
};

export function GridTable(props: GridProps) {
  let classes = useStyles();

  const [rowsSkeleton, setRSkeleton] = useState<Array<any>>([]);
  const [columnsSkeleton, setCSkeleton] = useState<Array<GridColDef>>([]);
  const [actionPanelShow, setActionPanelShow] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [deleteAction, setDeleteAction] = useState<boolean>(false);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  useEffect(() => {
    let cols = props.columns.map((column: GridColDef) => {
      let _c = { ...column };
      _c.renderCell = (params) => (
        <Skeleton variant="rectangular" width={210} height={10} />
      );
      return _c;
    });

    let ln = 5;
    let rows = [];
    let _i = 0;
    while (_i < ln) {
      let row = {} as any;
      cols.map((e, index) => {
        if (cols[index]) {
          row[e.field] = cols[index].field;
        }
      });
      row._id = _i;
      rows.push(row);
      _i++;
    }

    setCSkeleton(cols);
    setRSkeleton(rows);
  }, []);

  const pageChange = (p: number) => {
    // let page = p + 1; // it is because current_page from laravel is +1
    props.query([{ name: "page", value: p }]);
    setPage(p);
  };

  const pageSizeChange = (z: number) => {
    props.query([{ name: "per_page", value: z }]);
  };

  const pageFilter = (
    models: GridFilterModel,
    details: GridCallbackDetails
  ) => {};

  const sortChange = (model: GridSortModel, details: GridCallbackDetails) => {
    let queries: Array<IUrlQuery> = [];

    model.map((m) => {
      queries.push({ name: "sort_by", value: m.field });
      queries.push({ name: "sort", value: m.sort ?? "asc" });
    });

    props.query(queries);
  };

  const onCellEditStart = (params: GridCellParams, event: MuiEvent) => {
    if (props.onCellEditStart) props.onCellEditStart(params, event);
  };

  const onCellEditStop = (params: GridCellParams, event: MuiEvent) => {
    if (props.onCellEditStop) props.onCellEditStop(params, event);
  };

  const onCellEditCommit = (
    params: GridCellEditCommitParams,
    event: MuiEvent
  ) => {
    if (props.onCellEditCommit) props.onCellEditCommit(params, event);
  };

  const [open, setOpen] = useState<boolean>(false);
  const timeOut = useRef<any>();

  const sendQuery = (value: string) => {
    if (timeOut.current) clearTimeout(timeOut.current);

    timeOut.current = setTimeout(() => {
      props.search && props.search([{ name: "keyword", value }]);
    }, 500);
  };

  const intl = useIntl();

  return (
    <Box>
      <Grid
        container
        sx={{ justifyContent: "space-between", marginBottom: "20px" }}
      >
        <Grid item sm={12} xs={12} md={12} lg={6} xl={6} sx={{ height: 40 }}>
          <TextField
            variant="standard"
            value={searchValue}
            onChange={(e) => {
              let value = e.target.value;
              setSearchValue(value);

              sendQuery(value);
            }}
            placeholder={intl.formatMessage({
              id: "LABEL.SEARCH",
              defaultMessage: "Search",
            })}
            InputProps={{
              classes: { input: classes.input },
              startAdornment: (
                <SearchOutlinedIcon sx={{ ml: 2 }} fontSize="small" />
              ),
              endAdornment: (
                <IconButton
                  title="Clear"
                  aria-label="Clear"
                  size="small"
                  style={{ visibility: searchValue ? "visible" : "hidden" }}
                  onClick={() => {
                    setSearchValue("");

                    sendQuery("");
                  }}
                >
                  <ClearOutlinedIcon fontSize="small" />
                </IconButton>
              ),
            }}
            sx={{
              width: {
                xs: 1,
                sm: "auto",
              },
              backgroundColor: "#E1E6FB",
              opacity: "0.5",
              border: "1px solid #9FA5BB",
              height: 40,
              borderRadius: "38px",
              marginTop: "0px!important",
              marginBottom: "20px!important",
              m: (theme) => theme.spacing(1, 0.5, 1.5),
              "& .MuiSvgIcon-root": {
                mr: 0.5,
              },
              "& .MuiInput-underline:before": {
                content: "unset",
              },
              "& .MuiInput-underline:after": {
                content: "unset",
              },
              "& .Mui-focused": {
                border: "unset",
              },
              "& .MuiInput-root": {
                height: 40,
              },
            }}
          />
        </Grid>
        <Grid
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            height: 40,
          }}
          item
          sm={12}
          xs={12}
          md={12}
          lg={6}
          xl={6}
        >
          <Grid
            sx={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            {props.filterToolbar?.filters && (
              <IconButton
                className={classes.iconButtonFilter}
                onClick={() => {
                  setOpen(!open);
                }}
                sx={{
                  width: "124px",
                  height: "40px",
                  display: "flex",
                  backgroundColor: "#E1E6FB",
                  borderRadius: 1,
                  marginRight: 2,
                  gap: "6px",
                }}
              >
                <svg
                  width="19"
                  height="20"
                  viewBox="0 0 19 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.8313 20C10.651 20 10.4755 19.9415 10.3313 19.8333L6.99795 17.3333C6.89445 17.2557 6.81045 17.1551 6.75259 17.0393C6.69474 16.9236 6.66462 16.796 6.66462 16.6667V11.9833L0.817949 5.40583C0.402716 4.9374 0.131599 4.35901 0.0371826 3.74019C-0.0572336 3.12138 0.0290694 2.48846 0.285719 1.91751C0.542368 1.34656 0.958442 0.861882 1.48393 0.521721C2.00942 0.181561 2.62197 0.000395349 3.24795 0L15.0813 0C15.7072 0.000734209 16.3196 0.182199 16.8449 0.522589C17.3702 0.862978 17.786 1.34781 18.0423 1.91882C18.2987 2.48983 18.3847 3.12273 18.2901 3.74146C18.1954 4.36018 17.9241 4.93841 17.5088 5.40667L11.6646 11.9833V19.1667C11.6646 19.3877 11.5768 19.5996 11.4205 19.7559C11.2643 19.9122 11.0523 20 10.8313 20V20ZM8.33128 16.25L9.99795 17.5V11.6667C9.99812 11.4626 10.0731 11.2657 10.2088 11.1133L16.2655 4.29917C16.4674 4.0709 16.5992 3.7892 16.645 3.48788C16.6908 3.18655 16.6486 2.87842 16.5236 2.60046C16.3986 2.32251 16.196 2.08655 15.9401 1.92091C15.6843 1.75527 15.3861 1.66699 15.0813 1.66667H3.24795C2.94333 1.66713 2.6453 1.75546 2.38962 1.92105C2.13394 2.08665 1.93146 2.3225 1.80646 2.60029C1.68146 2.87809 1.63925 3.18605 1.68489 3.48723C1.73053 3.78842 1.86209 4.07004 2.06378 4.29833L8.12128 11.1133C8.25663 11.2659 8.33134 11.4627 8.33128 11.6667V16.25Z"
                    fill="#556EE6"
                  />
                </svg>
                <Typography
                  sx={{
                    fontSize: 16,
                  }}
                  variant="inherit"
                >
                  Filter
                </Typography>

                <ExpandMoreIcon
                  sx={{
                    color: "#556EE6",
                    transition: "0.5s all",
                    transform: `rotate(${!open ? 0 : "3.142rad"})`,
                  }}
                />
              </IconButton>
            )}
            {props.actionToolbar?.status && props.actionToolbar.status()}

            {(props.filterToolbar?.actionPanel ||
              props.filterToolbar?.action) && (
              <IconButton
                className={`${classes.iconButtonAdd}`}
                sx={{
                  width: "124px",
                  height: "40px",
                  display: "flex",
                  backgroundColor: "#556EE6",
                  borderRadius: 1,
                  gap: "6px",
                }}
                onClick={() => {
                  if (props.filterToolbar?.actionPanel) {
                    setActionPanelShow(true);
                  } else if (props.filterToolbar?.action) {
                    props.filterToolbar?.action();
                  }
                }}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.75 4.75C10.75 4.33579 10.4142 4 10 4C9.58579 4 9.25 4.33579 9.25 4.75V9.25H4.75C4.33579 9.25 4 9.58579 4 10C4 10.4142 4.33579 10.75 4.75 10.75H9.25V15.25C9.25 15.6642 9.58579 16 10 16C10.4142 16 10.75 15.6642 10.75 15.25V10.75H15.25C15.6642 10.75 16 10.4142 16 10C16 9.58579 15.6642 9.25 15.25 9.25H10.75V4.75Z"
                    fill="white"
                  />
                </svg>
                <Typography
                  sx={{
                    fontSize: 16,
                    color: "#fff",
                  }}
                  variant="inherit"
                >
                  {intl.formatMessage({
                    id: "STANDARD.NEW",
                    defaultMessage: "New",
                  })}
                </Typography>
              </IconButton>
            )}
            {props.filterToolbar?.exportPanel && (
              <IconButton
                className={`${classes.iconButtonExport}`}
                sx={{
                  width: "124px",
                  height: "40px",
                  display: "flex",
                  backgroundColor: "#4BB543",
                  borderRadius: 1,
                  marginLeft: 2,
                  marginRight: 4.5,
                  gap: "6px",
                }}
                onClick={props.filterToolbar?.exportPanel}
              >
                <Typography
                  sx={{
                    fontSize: 16,
                    color: "#fff",
                  }}
                  variant="inherit"
                >
                  Export
                </Typography>
              </IconButton>
            )}
            {props.filterToolbar?.penaltyDocuments && (
              <IconButton
                className={`${classes.iconButtonPenaltyDoc}`}
                sx={{
                  width: "124px",
                  height: "40px",
                  display: "flex",
                  backgroundColor: "#0D99FF",
                  borderRadius: 1,
                  marginLeft: 2,
                  marginRight: 4.5,
                  gap: "6px",
                }}
                onClick={props.filterToolbar?.penaltyDocuments}
              >
                <Typography
                  sx={{
                    fontSize: 16,
                    color: "#fff",
                  }}
                  variant="inherit"
                >
                  Documents
                </Typography>
              </IconButton>
            )}
          </Grid>
        </Grid>
      </Grid>
      {props.archiveOption && deleteAction && (
        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          lg={12}
          xl={12}
          sx={{ height: 40, marginBottom: "20px" }}
        >
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton
              sx={{
                backgroundColor: "#FBB73C",
                width: 124,
                height: 40,
                borderRadius: "4px",
                "&:hover": {
                  backgroundColor: "#FBB73C",
                },
              }}
              onClick={() => {
                if (!selectedIds.length) Snackbar.error("Choose least 1 row");

                props.archiveAction && props.archiveAction(selectedIds);
              }}
            >
              <Typography color={"#fff"}>Archive</Typography>
            </IconButton>
          </Box>
        </Grid>
      )}

      {props.filterToolbar?.filters &&
        props.filterToolbar.filters({
          show: open,
        })}
      {props.filterToolbar?.quickCards ? (
        <FilterCards data={props.filterToolbar?.quickCards} />
      ) : (
        props.filterToolbar?.customQuickCards ?? <></>
        // <>Hello</>
      )}
      <Paper className={classes.table}>
        <div style={props.style}>
          <DataGrid
            page={10}
            ref={props.tableRef}
            sx={{
              "& .MuiDataGrid-cell:focus-within": {
                outline: "none",
              },
              "& .MuiDataGrid-columnHeadersInner": {
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8,
              },
              "& .MuiDataGrid-columnHeaderTitle, .MuiDataGrid-cell": {
                fontSize: 13,
              },
              "& .MuiDataGrid-cell": {
                fontWeight: 400,
              },
              "& .MuiDataGrid-iconSeparator, .MuiDataGrid-menuIconButton": {
                display: "none",
              },
              borderRadius: 2,
              ...props.sx,
            }}
            components={{
              LoadingOverlay: CustomLoadingOverlay,
              Footer: CustomFooter,
            }}
            componentsProps={{
              pagination: {
                pageSize: props.pageSize ?? 10,
                count: props.count,
                query: props.query,
              },
              footer: {
                total: props.customTotal ?? "0",
                currency: props.customTotalCurrency ?? "DKK",
              },
            }}
            rows={props.skeleton ? rowsSkeleton : props.rows}
            columns={props.skeleton ? columnsSkeleton : props.columns}
            getRowId={(row) => row._id}
            rowsPerPageOptions={[5, 10, 15, 50]}
            pageSize={props.pageSize ? props.pageSize : 10}
            rowCount={props.count}
            loading={props.loading}
            checkboxSelection
            onSelectionModelChange={(ids) => {
              setDeleteAction(!!ids.length);
              let idsArr: string[] = [];
              ids.map((id) => idsArr.push(id.toString()));
              setSelectedIds(idsArr);
              props.actionIds && props.actionIds(idsArr);
            }}
            hideFooter={!props.customTotal}
            autoHeight
            disableSelectionOnClick
            paginationMode="server"
            filterMode="server"
            hideFooterSelectedRowCount={false}
            filterModel={props.filterModel}
            onSortModelChange={sortChange}
            onPageSizeChange={pageSizeChange}
            onFilterModelChange={pageFilter}
            onCellEditStop={(params: GridCellParams, event: MuiEvent) =>
              onCellEditStop(params, event)
            }
            onCellEditStart={(params: GridCellParams, event: MuiEvent) =>
              onCellEditStart(params, event)
            }
            onCellEditCommit={(
              params: GridCellEditCommitParams,
              event: MuiEvent
            ) => onCellEditCommit(params, event)}
            localeText={{
              noRowsLabel: intl.formatMessage({
                id: "STANDARD.NOROWS",
                defaultMessage: "No rows",
              }),
            }}
          />
        </div>
      </Paper>
      <CustomPagination
        query={props.query}
        count={props.count ?? 0}
        pageSize={props.pageSize ?? 10}
        page={page}
        pageChange={(p: number) => pageChange(p)}
      />
      {props.filterToolbar?.actionPanel &&
        props.filterToolbar.actionPanel({
          open: actionPanelShow,
          close: () => {
            setActionPanelShow(false);
          },
        })}
    </Box>
  );
}

const CustomFooter = (props: { total: number; currency: string }) => {
  return (
    <Box
      sx={{
        padding: "0 15px",
        height: 52,
        display: "flex",
        alignItems: "center",
      }}
    >
      <Grid container sx={{ justifyContent: "space-between", margin: 0 }}>
        <Grid
          sx={{
            textAlign: "right",
            display: "flex",
            justifyContent: "center",
            gap: 11,
          }}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
        >
          <Typography fontSize={13} fontWeight={500} variant={"inherit"}>
            Total
          </Typography>
          <Typography
            color={"red"}
            fontSize={13}
            fontWeight={600}
            variant={"inherit"}
          >
            {"-" + props.total.toString()} {props.currency}.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

const CustomPagination = (props: {
  query: (value: Array<IUrlQuery>) => void;
  count: number;
  pageSize: number;
  page: number;
  pageChange: (p: number) => void;
}) => {
  const [_page, setPage] = useState<number>(1);
  const pageChange = (event: any) => {
    setPage(event.target.value);
    props.query([{ name: "per_page", value: event.target.value }]);
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        marginTop: "12px",
        justifyContent: "space-between",
        "& .MuiTablePagination-select": {
          backgroundColor: "#ffff",
          width: "10px",
          height: "24px",
          lineHeight: "24px",
          borderRadius: "6px",
        },
        "& .MuiInputBase-root": {
          marginLeft: 0,
        },
        "& .MuiPagination-root": {
          display: "flex",
        },
        "& .MuiPaginationItem-root:not(.MuiPaginationItem-ellipsis)": {
          backgroundColor: "#fff",
          border: "1px solid #fff",
          color: "#000",
        },
        "& .MuiPaginationItem-root.Mui-selected": {
          backgroundColor: "#0D99FF",
          border: "1px solid #0D99FF",
          color: "#fff",
        },
        "& .MuiPaginationItem-root.Mui-selected:hover": {
          backgroundColor: "#0D99FF",
        },
        "& .MuiPaginationItem-root:hover": {
          backgroundColor: "#fff",
        },
        "& .MuiPaginationItem-ellipsis:hover": {
          backgroundColor: "transparent",
        },
      }}
    >
      <TablePagination
        sx={{
          padding: 0,
          border: 0,
          "& .MuiToolbar-root": {
            paddingLeft: 0,
          },
        }}
        labelRowsPerPage={""}
        rowsPerPageOptions={[5, 10, 15, 50]}
        backIconButtonProps={{ sx: { display: "none" } }}
        nextIconButtonProps={{ sx: { display: "none" } }}
        labelDisplayedRows={() => ""}
        rowsPerPage={props.pageSize ? props.pageSize : 10}
        count={props.count ?? 0}
        onPageChange={(p) => pageChange(p)}
        onRowsPerPageChange={(p) => pageChange(p)}
        page={_page}
      />
      <Pagination
        color="primary"
        variant="outlined"
        shape="rounded"
        page={props.page}
        hideNextButton
        hidePrevButton
        count={Math.ceil(props.count / props.pageSize)}
        // @ts-expect-error
        renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
        onChange={(event: React.ChangeEvent<unknown>, value: number) =>
          props.pageChange(value)
        }
      />
    </Box>
  );
};

const FilterCards = (props: {
  data:
    | Array<{
        title: string;
        onClick: (isActive: boolean) => void;
        icon?: string;
        value?: number;
      }>
    | undefined;
}) => {
  const [clickedCard, setClickedCard] = useState<number | null>(null);

  const handleClick = (index: number) => {
    if (clickedCard !== index) {
      setClickedCard(index);
    } else {
      setClickedCard(null);
    }
  };

  return (
    <>
      <Grid container spacing={1} sx={{ marginBottom: 3 }}>
        {props.data?.map((card, index) => {
          return (
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={3}
              xl={3}
              className={`filter`}
              onClick={() => {
                handleClick(index);
                card.onClick(clickedCard === index);
              }}
            >
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  backgroundColor: "#fff",
                  height: 80,
                  borderRadius: 2,
                  padding: 2,
                  cursor: "pointer",
                  outline: clickedCard === index ? "1px solid #556EE6" : "",
                }}
              >
                <Grid>
                  <Typography
                    sx={{ marginBottom: 2, fontWeight: 500, fontSize: 13 }}
                    variant={"inherit"}
                  >
                    {card.title}
                  </Typography>
                  <Typography
                    sx={{ fontWeight: 500, fontSize: 16 }}
                    variant={"inherit"}
                  >
                    {card.value}
                  </Typography>
                </Grid>
                <Grid
                  sx={{
                    width: 50,
                    height: 50,
                    borderRadius: "50%",
                    backgroundColor: "#556EE6",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <i
                    style={{
                      fontSize: 17,
                      height: 20,
                      color: "#fff",
                    }}
                    className={`fi ${card.icon}`}
                  ></i>
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

function CustomLoadingOverlay() {
  return (
    <GridOverlay>
      <div style={{ position: "absolute", top: 0, width: "100%" }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  );
}

function escapeRegExp(value: string): string {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

interface QuickSearchToolbarProps {
  clearSearch: () => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: string | undefined;
}

interface ICustomToolBar extends QuickSearchToolbarProps {
  actionPanel?: JSX.Element;
  extra?: JSX.Element;
  quickSearch: boolean;
  columns: boolean;
  filter: boolean;
  density: boolean;
  export: boolean;
  onTextChange: (text: string) => {};
}

const CustomToolbar: FC<ICustomToolBar> = (props) => {
  const [searchValue, setSearchValue] = useState<string>("");
  const timeOut = useRef<any>();

  const clear = () => {
    props.onTextChange("");
    setSearchValue("");
  };

  useEffect(() => {
    timeOut.current = setTimeout(() => {});
    return () => clear();
  }, []);

  const searchQuery = (needle: string) => {
    if (needle.length === 0) {
      props.onTextChange("");
      return;
    }
    props.onTextChange(needle);
  };

  return (
    <GridToolbarContainer
      style={{
        height: "100px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
      }}
    >
      <Grid style={{ padding: "10px" }}>
        <QuickSearchToolbar
          clearSearch={clear}
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value);
            if (!timeOut.current) return;

            clearInterval(timeOut.current);
            timeOut.current = setTimeout(searchQuery, 500, e.target.value);
          }}
        />
        {props.quickSearch && <GridToolbarColumnsButton />}
        {props.filter && <GridToolbarFilterButton />}
        {props.density && <GridToolbarDensitySelector />}
        {props.export && <GridToolbarExport />}
        {props.extra}
      </Grid>
      <Grid>{props.actionPanel}</Grid>
    </GridToolbarContainer>
  );
};

function QuickSearchToolbar(props: QuickSearchToolbarProps) {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
      }}
    >
      <TextField
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search…"
        InputProps={{
          startAdornment: <SearchOutlinedIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? "visible" : "hidden" }}
              onClick={props.clearSearch}
            >
              <ClearOutlinedIcon fontSize="small" />
            </IconButton>
          ),
        }}
        sx={{
          width: {
            xs: 1,
            sm: "auto",
          },
          m: (theme) => theme.spacing(1, 0.5, 1.5),
          "& .MuiSvgIcon-root": {
            mr: 0.5,
          },
          "& .MuiInput-underline:before": {
            borderBottom: 1,
            borderColor: "divider",
          },
        }}
      />
    </Box>
  );
}
